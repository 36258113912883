<template>
  <div class="v-big-constructor-old">
    <div
      v-if="bigConstructor.ConstructorTextAbove"
      class="v-big-constructor-old-row-text-above"
      v-html="sanitize(bigConstructor.ConstructorTextAbove)"
    />
    <div
      class="v-big-constructor-old-row-pic"
      :data-test-id="`${dataTestId}-row-pic`"
    >
      <div
        v-if="selectedOption"
        class="v-big-constructor-old-picture"
      >
        <slot />
      </div>
    </div>
    <div class="v-big-constructor-old-row">
      <div class="v-big-constructor-old-categories">
        <arora-option-slider
          :data-test-id="dataTestId"
          :label="translate('orderType.orderType')"
          :options="availableCategories"
          :threshold="appConfig.VueSettingsPreRun.BigConstructorOptionThreshold"
          v-model:selected="selectedCategory"
        >
          <template #option="option: { value: Category | null }">
            <span
              v-if="option.value"
              v-html="option.value.Name"
            />
          </template>
        </arora-option-slider>
      </div>
      <div
        v-if="selectedCategory"
        class="v-big-constructor-old-category-mods"
        :data-test-id="`${dataTestId}-category-mods`"
      >
        <div
          v-for="mod in selectedCategory.ConstructorCategoryModifiers"
          :key="mod.ID"
          class="v-big-constructor-mod-old-single"
        >
          <menu-product-constructor-mod-default
            :key="mod.ID"
            :amount="
              mod.CategoryID !== catId
                ? selectedModsByCategory[mod.CategoryID]
                  ? selectedModsByCategory[mod.CategoryID][mod.ID]
                  : undefined
                : mod.ID === selectedOption?.ID && !mod.InStopList
                  ? 1
                  : 0
            "
            :disabled="
              (mod.CategoryID !== catId &&
                numberOfAvailableModsByCategory[mod.CategoryID] <= 0 &&
                selectedModsByCategory[mod.CategoryID][mod.ID] === 0) ||
              mod.InStopList
            "
            :data-test-id="dataTestId"
            :mod="mod"
            :onclick="async () => await modClick(mod)"
            :onremove="() => modRemove(mod)"
          />
        </div>
      </div>
    </div>
    <div class="v-big-constructor-old-row">
      <div
        class="v-big-constructor-old-ingredients-title"
        v-html="
          bigConstructor.ConstructorBaseText?.length === 0
            ? translate('constructorBigPage.base')
            : sanitize(bigConstructor.ConstructorBaseText)
        "
      />
      <div class="v-big-constructor-old-mods-below">
        <span
          class="v-big-constructor-old-mods-below-mod-name"
          v-html="sanitize(selectedOption?.Name ?? '')"
        />
        <div class="v-stepper-counter-wrapper" />
        <common-currency :amount="basePay" />
        <div class="v-big-constructor-old-mods-below-mod-remove" />
      </div>
    </div>

    <div class="v-big-constructor-old-row">
      <div
        class="v-big-constructor-old-ingredients-title"
        v-html="translate('constructorBigPage.ingredients')"
      />

      <TransitionGroup name="list">
        <template v-for="cat in bigConstructor?.Categories ?? []">
          <template v-if="selectedModsByCategory && selectedModsByCategory[cat.ID]">
            <template
              v-for="mod in cat.ConstructorCategoryModifiers"
              :key="mod.ID"
            >
              <div
                v-if="
                  selectedModsByCategory[cat.ID][mod.ID] && selectedModsByCategory[cat.ID][mod.ID] > 0
                "
                class="v-big-constructor-old-mods-below v-mb-sm"
              >
                <span
                  class="v-big-constructor-old-mods-below-mod-name"
                  v-html="sanitize(mod.Name)"
                />
                <div class="v-stepper-counter-wrapper">
                  <arora-stepper
                    :data-test-id="`${dataTestId}-modifier-stepper-${mod.ID}`"
                    :disable-plus-button="selectedModsByCategory[cat.ID][mod.ID] >= mod.ValueMax"
                    :label="translate('addToCartButton.addToCart')"
                    :on-decrement="() => onDecrement(cat.ID, mod.ID)"
                    :on-increment="() => modClick(mod)"
                    :value="selectedModsByCategory[cat.ID][mod.ID]"
                  />
                </div>
                <common-currency :amount="mod.Price * selectedModsByCategory[cat.ID][mod.ID]" />
                <div class="v-big-constructor-old-mods-below-mod-remove">
                  <arora-button
                    class-name="v-btn-square v-square-small"
                    :label="translate('cardPage.deleteMod')"
                    @click="() => (selectedModsByCategoryLocal[cat.ID][mod.ID] = 0)"
                  >
                    <icon-general-trashcan />
                  </arora-button>
                </div>
              </div>
            </template>
          </template>
        </template>
      </TransitionGroup>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ModifierCounts, ModsByCategory } from '~types/clientStore'
import type { Category, ConstructorCategoryModifier, Option } from '~types/menuStore'
import type { ConstructorBigCommon } from '~types/props'

import { type GUID, useCommon } from '@arora/common'

const { bigConstructor, selectedModsByCategory, selectedOption, setSelectedOption } =
  defineProps<ConstructorBigCommon>()

const appConfig = useAppConfig()
const menuStore = useMenuStore()

const { sanitize, translate } = useI18nSanitized()
const { objectKeys } = useCommon()

const availableCategories = ref<Category[]>([])
const selectedModsByCategoryLocal = ref<ModsByCategory>(selectedModsByCategory)
const catId: GUID = 'cat' as GUID

onMounted(() => {
  const optionsKeys = bigConstructor.Options ? objectKeys(bigConstructor.Options) : []

  if (optionsKeys.length > 0) {
    const constructorCategoryModifiers: ConstructorCategoryModifier[] = []
    selectedModsByCategoryLocal.value[catId] = {}
    for (const key of optionsKeys)
      for (const option of Object.values(bigConstructor?.Options[key] as Option[])) {
        selectedModsByCategoryLocal.value[catId][option.ID] = selectedOption?.ID === option.ID ? 1 : 0
        constructorCategoryModifiers.push({
          Active: true,
          CategoryID: catId as GUID,
          Description: option.Description,
          ID: option.ID,
          InStopList: menuStore.StopListOptionIds.has(option.ID),
          Name: option.Name,
          Price: option.Price,
          ProductAttachedTo: key as GUID,
          Recommended: false,
          SmallImage: option.SmallImage,
          ValueDefault: option.IsDefault && !menuStore.StopListOptionIds.has(option.ID) ? 1 : 0,
          ValueMax: 1,
          ValueMin: 0
        })
      }

    const constructorBaseText = bigConstructor.ConstructorBaseText ?? ''

    const optionsCategory: Category = {
      ConstructorCategoryModifiers: constructorCategoryModifiers,
      EnableMaxLimit: false,
      ID: catId as GUID,
      IngredientsMax: 1,
      IngredientsMin: 1,
      Name:
        constructorBaseText.length > 0
          ? sanitize(constructorBaseText)
          : translate('constructorBigPage.base'),
      TypeRequire: 0
    }

    availableCategories.value.push(optionsCategory)

    if (selectedCategory.value === null) {
      selectedCategory.value = optionsCategory
    }
  }

  for (const category of bigConstructor.Categories) {
    availableCategories.value.push(category)
  }

  if (selectedCategory.value === null) {
    selectedCategory.value = bigConstructor.Categories[0]
  }
})
const selectedCategory = ref<Category | null>(null)

const basePay = computed<number>(() => selectedOption?.Price ?? 0)

const numberOfAvailableModsByCategory = computed<ModifierCounts>(() => {
  const result: ModifierCounts = {}

  for (const category of bigConstructor?.Categories ?? []) {
    let count = 0
    for (const mod of category.ConstructorCategoryModifiers) {
      if (mod.ProductAttachedTo === selectedOption?.ID || mod.ProductAttachedTo === bigConstructor?.ID) {
        count += selectedModsByCategoryLocal.value[category.ID][mod.ID]
      }
    }

    result[category.ID] =
      category.EnableMaxLimit && category.IngredientsMax ? category.IngredientsMax - count : 10 - count
  }

  return result
})
const modLimitPerCategory = computed<ModifierCounts>(() => {
  const result: ModifierCounts = {}

  for (const category of bigConstructor?.Categories ?? []) {
    result[category.ID] = category.EnableMaxLimit ? (category.IngredientsMax ?? 10) : 10
  }

  return result
})

async function modClick(mod: ConstructorCategoryModifier): Promise<void> {
  if (mod.CategoryID === catId) {
    setSelectedOption(mod.ProductAttachedTo, mod.ID)

    return
  }
  if (!selectedModsByCategoryLocal.value[mod.CategoryID]) {
    selectedModsByCategoryLocal.value[mod.CategoryID] = {}
  }
  if (
    numberOfAvailableModsByCategory.value[mod.CategoryID] > 0 &&
    selectedModsByCategoryLocal.value[mod.CategoryID][mod.ID] <
      modLimitPerCategory.value[mod.CategoryID] &&
    selectedModsByCategoryLocal.value[mod.CategoryID][mod.ID] < mod.ValueMax
  ) {
    selectedModsByCategoryLocal.value[mod.CategoryID][mod.ID]++
  }
}

function modRemove(mod: ConstructorCategoryModifier): void {
  if (mod.CategoryID === catId) {
    return
  }
  selectedModsByCategoryLocal.value[mod.CategoryID][mod.ID] = 0
}

async function onDecrement(catId: GUID, modId: GUID): Promise<void> {
  selectedModsByCategoryLocal.value[catId][modId]--
}
</script>

<style lang="scss">
@use 'assets/variables';
@use 'assets/mixins';

.v-big-constructor-old {
  flex: 0 0 100%;
  width: 100%;
}

.v-big-constructor-old-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px 15px;

  @include mixins.md {
    padding: 25px 5px;
  }
}

.v-big-constructor-old-row-pic {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px 15px;

  @include mixins.md {
    padding: 10px 5px;
  }
}

.v-big-constructor-old-row-text-above {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 25px 15px;
}

.v-big-constructor-old-categories {
  flex: 0 0 100%;
  max-width: 100%;
}

.v-big-constructor-old-category {
  padding: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  cursor: pointer;
  box-shadow: variables.$InputShadow;
  border: variables.$BorderWidth solid variables.$BorderColor;
  border-radius: variables.$BorderRadius;
  text-align: center;

  @include mixins.trans;

  img {
    margin-bottom: 5px;
  }

  @include mixins.md {
    flex: 0 0 calc(33.33% - 10px);
    max-width: calc(33.33% - 10px);
  }

  &.selected {
    background: variables.$BodyElementsBackground;
    border-width: 0;
  }

  &:hover {
    border-color: variables.$PrimaryBackgroundColor;
  }
}

.v-big-constructor-old-category-mods {
  flex: 0 0 100%;
  max-width: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: baseline;
  justify-content: flex-start;
  text-align: center;

  margin-left: -15px;
  margin-right: -15px;
  overflow: hidden;
}

.v-big-constructor-mod-old-single {
  flex: 0 0 16.67%;
  max-width: 16.67%;
  padding: 7px;

  @include mixins.md {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}

.v-big-constructor-old-picture {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 15px;

  img {
    max-width: 100%;
    height: auto;
  }

  @include mixins.md {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.v-big-constructor-old-ingredients-title {
  font-weight: bolder;
  font-size: 22px;
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 10px;
}

.v-big-constructor-old-ingredients {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 5px;
}

.v-big-constructor-old-mods-below {
  flex: 0 0 100%;
  max-width: 100%;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border-bottom: variables.$BorderWidth solid variables.$BorderColorLight;

  .v-big-constructor-old-mods-below-mod-name {
    flex: 0 0 65%;
    max-width: 65%;
    font-size: 16px;

    @include mixins.md {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 8px;
    }
  }

  .v-currency-wrapper {
    flex: 0 0 15%;
    max-width: 15%;
    font-size: 16px;
    text-align: center;
  }

  .v-stepper-counter-wrapper {
    flex: 0 0 calc(15% - 10px);
    max-width: calc(15% - 10px);
    margin-right: 10px;

    input {
      margin: 0;
    }

    @include mixins.md {
      flex: 0 0 calc(45% - 10px);
      max-width: calc(45% - 10px);
    }
  }

  .v-big-constructor-old-mods-below-mod-remove {
    flex: 0 0 5%;
    max-width: 5%;

    @include mixins.md {
      flex: 0 0 15%;
      max-width: 15%;
    }
  }
}
</style>
